import { useMutation, useQuery } from "@tanstack/vue-query";

export const useAuthParticipent = defineStore("authParticipent", () => {
  const { $axios } = useNuxtApp();

  const route = useRoute();
  const pathSplit = route.fullPath.split("/");
  const model_type = ref(pathSplit[3]);
  const mouse = ref({ x: 0, y: 0 });
  const subdomain = window.location.hostname.split(".").slice(0, -2).join(".");

  const account = ref<any>(null);
  const user = ref<any>(null);
  const me_auth = ref<any>(null);


  const { data: accountData, refetch: refetchAccount } = useQuery({
    queryKey: ["account", subdomain],
    queryFn: ({ queryKey }) => {
      return $axios.get(`/v1/accounts/${queryKey[1]}`).then((res) => res.data);
    },
    enabled: computed(() => Boolean(subdomain)),
  });

  watch(
    () => accountData.value,
    (value) => {
      account.value = value;
    },
  );

  const {
    mutateAsync: refetch,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async () =>
      await $axios
        .get(
          `/v1/sessions/${route.params.id}/participants/${route.params.user_id}`,
        )
        .then((res) => res.data),
    onSuccess: (data) => {
      user.value = data;
    },
    onError: (error) => {
      user.value = null;
      console.log(error);
    },
  });


  const {
    mutateAsync: meRefetch,
    isSuccess: meIsSuccess,
    isPending: meIsPending,
  } = useMutation({
    mutationFn: async () =>
      await $axios.get("/user").then((res) => res.data),
    onSuccess: (data) => {
      me_auth.value = data;
    },
    onError: (error) => {
      me_auth.value = null;
      console.log(error);
    },
  });

  const { mutateAsync: logout, isSuccess: isLoggedOut } = useMutation({
    mutationFn: async (data) => {
      await $axios.post("/logout");
    },
    onSuccess: () => {
      user.value = null;
      navigateTo(
        `/sessions/${route.params.id}/${model_type.value}/${route.params.user_id}`,
      );
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutateAsync: logoutNoRoute, isSuccess: isLoggedOutRoute } =
    useMutation({
      mutationFn: async (data) => {
        await $axios.post("/logout");
      },
      onSuccess: () => {
        user.value = null;
      },
      onError: (error) => {
        console.log(error);
      },
    });

  return {
    logoutNoRoute,
    refetchAccount,
    model_type,
    user,
    mouse,
    isPending,
    refetch,
    isLoggedOut,
    isSuccess,
    logout,
    subdomain,
    account,
    me_auth,
    meRefetch,
    meIsSuccess,
    meIsPending,
  };
});
